<template>
  <b-overlay :show="isSubmitting" rounded="sm">
    <div>
      <b-card>
        <div class="d-flex justify-content-left">
          <div
            class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
          >
            <feather-icon icon="HomeIcon" size="35" color="white" />
          </div>
          <h3 class="mr-5 text">PickUp Address</h3>
        </div>
        <div class="w-100 mt-3">
          <div class="d-flex mb-2">
            <p
              v-if="pickUp.address_line_2"
              class="form-control mx-2 d-flex align-items-center"
            >
              {{ pickUp.address_unit }}, {{ pickUp.address_line_1 }},
              {{ pickUp.address_line_2 }}, {{ pickUp.address_area }},
              {{ pickUp.address_postcode }}, {{ pickUp.address_state }},
              {{ pickUp.address_country }}
            </p>
            <p v-else class="form-control d-flex align-items-center mx-2">
              {{ pickUp.address_line_1 }}
            </p>
          </div>
        </div>
        <div class="w-100">
          <div class="d-flex">
            <div
              class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
            >
              <feather-icon icon="ShoppingCartIcon" size="35" color="white" />
            </div>
            <h3 class="text">Cart</h3>
          </div>
          <MyTable :columns="columns" :rows="rows" :is-loading="isLoading">
          </MyTable>
        </div>
        <div>
          <div class="d-flex">
            <div
              class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
            >
              <feather-icon icon="DollarSignIcon" size="35" color="white" />
            </div>
            <h3 class="text">Total</h3>
          </div>
          <div class="d-flex align-items-center justify-content-between ml-5">
            <b-row align-h="between">
              <b-col>
                <div>
                  <b-row>
                    <b-col><h4>Voucher Code</h4></b-col>
                    <b-col>
                      <select
                        class="form-control form-select rounded w-50"
                        v-model="voucher"
                      >
                        <option value="">No Voucher Code</option>
                        <option
                          v-for="voucherList in voucherList"
                          :key="voucherList.id"
                          :value="voucherList"
                        >
                          {{ voucherList.voucher_code }}
                        </option>
                      </select></b-col
                    >
                  </b-row>
                  <b-row>
                    <b-col><h4>Sub Total</h4></b-col>
                    <b-col>{{ $helpers.formatTotal(totals.subTotal) }}</b-col>
                  </b-row>
                  <b-row>
                    <b-col><h4>Voucher Discount</h4></b-col>
                    <b-col>RM 0.00</b-col>
                  </b-row>
                  <b-row>
                    <b-col><h4>Tax Charge</h4></b-col>
                    <b-col>{{ $helpers.formatTotal(totals.taxTotal) }}</b-col>
                  </b-row>
                  <b-row>
                    <b-col><h4>Grand Total</h4></b-col>
                    <b-col>{{ $helpers.formatTotal(totals.grandTotal) }}</b-col>
                  </b-row>
                </div></b-col
              >
              <b-col md="2" offset-md="4">
                <div>
                  <h4>WE ACCEPT</h4>
                  <img
                    :src="require('@/assets/images/logo/visa.png')"
                    class="img-fluid w-75 mr-5 pr-2"
                  /></div
              ></b-col>
            </b-row>
          </div>
        </div>
      </b-card>
      <div class="d-flex justify-content-center align-items-center mt-5">
        <b-button
          @click="onBack"
          variant="light"
          v-b-modal.modal-center
          class="box-shadow-1 px-5 mr-3 w-25"
          >Back</b-button
        >
        <b-button
          @click="onSubmit"
          variant="gradient-primary"
          v-b-modal.modal-center
          class="box-shadow-1 px-5 w-25"
          >Submit</b-button
        >
      </div>
    </div>
    <template #overlay>
      <div class="text-center">
        <b-spinner
          style="width: 3rem; height: 3rem"
          label="Large Spinner"
          variant="primary"
        ></b-spinner>
        <h3 class="mt-3">Creating your order. Please wait...</h3>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import MyTable from "@/views/components/MyTable.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserData } from "@/auth/utils";
export default {
  components: {
    MyTable,
  },

  data() {
    return {
      isSubmitting: false,
      isLoading: false,
      rangeDate: null,
      subTotal: "",
      taxCharge: "",
      voucherList: [],
      voucher: "",
      pricing: {},
      form: {},
      pickUp: {},
      double_charge: "",
      columns: [
        {
          label: "Remarks",
          field: "remarks",
        },
        {
          label: "Name",
          field: "first_name",
        },
        {
          label: "Phone",
          field: "contact",
        },
        {
          label: "Address",
          field: "address",
        },
        {
          label: "SKU",
          field: "sku",
        },
        {
          label: "Quantity",
          field: "quantity",
        },
        {
          label: "Size",
          field: "size",
        },
        {
          label: "Weight",
          field: "weight",
        },
      ],
      rows: [],
    };
  },
  created() {
    this.form = this.$route.params.data;
    this.pickUp = this.$route.params.pickUp;
    this.rows = this.form.deliveries;
    console.log(this.rows);
  },
  computed: {
    totals() {
      const initialTotals = { subTotal: 0, taxTotal: 0, grandTotal: 0 };
      const totals = this.rows.reduce((acc, row) => {
        acc.subTotal += row.sub_total; // Sum up all the sub_totals
        acc.taxTotal += row.tax; // Sum up all the taxes
        acc.grandTotal += row.sub_total + row.tax; // Calculate the grand total
        return acc;
      }, initialTotals);

      return totals;
    },
  },
  methods: {
    onSubmit() {
      this.isSubmitting = true;
      this.isDisabled = true;
      var baseURL = window.location.origin;
      var newPath = "/Distributor-Bulk-delivery/success";
      if (this.form.payment_method != "") {
        this.$http.post(`order/create`, this.form).then((response) => {
          if (response.data.status) {
            this.form.order_id = response.data.order_id;
            this.userData = getUserData();
            this.$http
              .get(
                `pay_order?amount=${this.totals.grandTotal}&order_id=${this.form.order_id}&return_url=${baseURL}${newPath}&name=${this.userData.name}&phone=${this.userData.phone}&email=${this.userData.email}&detail=MiniDC Bulk Order Payment`
              )
              .then((response) => {
                if (response.data.status) {
                  this.$router.push({
                    name: "Distributor-Bulk-delivery-success",
                    query: { order_id: this.form.order_id },
                  });
                } else {
                  if (response.data.errors) {
                    var errors = response.data.errors;
                    for (var a in errors) {
                      var error = errors[a];
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: error,
                          icon: "XIcon",
                          variant: "warning",
                        },
                      });
                      this.isSubmitting = false;
                      this.isDisabled = false;
                    }
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: response.data.message,
                        icon: "XIcon",
                        variant: "warning",
                      },
                    });
                  }
                  this.isSubmitting = false;
                  this.isDisabled = false;
                }
              });
          } else {
            if (response.data.errors) {
              var errors = response.data.errors;
              for (var a in errors) {
                var error = errors[a];
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error,
                    icon: "XIcon",
                    variant: "warning",
                  },
                });
              }
            } else if (typeof response.data.message == "array") {
              var errors = response.data.message;
              for (var a in errors) {
                var error = errors[a];
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error,
                    icon: "XIcon",
                    variant: "warning",
                  },
                });
                this.isSubmitting = false;
                this.isDisabled = false;
              }
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "XIcon",
                  variant: "warning",
                },
              });
              this.isSubmitting = false;
              this.isDisabled = false;
            }
          }
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Please choose a payment method",
            icon: "XIcon",
            variant: "warning",
          },
        });
        this.isSubmitting = false;
        this.isDisabled = false;
      }
    },
    onBack() {
      this.$router.push({
        name: "distributor-bulk-delivery",
        params: { data: this.form, pickUp: this.pickUp },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
.icon {
  background-color: #0f4392;
  height: 50px;
  width: 50px;
}
.text {
  width: 15rem;
}
.notActive {
  background-color: rgba(211, 18, 100, 0.58);
}
.tableBG {
  background-color: #f7f8fc;
}
.totalText {
  margin-top: 1vh;
  width: 9vw;
}
</style>
